.VideoCardContainer {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  background-color: white;
  border-radius: 20px;
  margin: 20px;
  margin-top: 40px;
  max-width: 1000px;
  width: 80%;
}
.TikTokVideoCardContainer {
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;
  background-color: white;
  border-radius: 20px;
  margin: 20px;
  margin-top: 60px;
  max-width: 800px;
  width: 50%;
}

.VideoDescription {
  padding: 20px 20px 20px 0px;
  flex: 1;
  min-width: 200px;
  min-height: 300px;
}

.VideoDescription > div {
  margin-left: 16px;
  margin-top: 0px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.6);
}

.VideoDescription > h1 {
  margin-bottom: 16px;
}

.TikTokVideoDescription {
  padding: 20px 0px 20px 20px;
  flex: 1;
  min-width: 200px;
}

.TikTokIframe {
  aspect-ratio: 9/16;
}

.TikTokVideoDescription > div {
  margin-right: 16px;
  margin-top: 0px;
  text-align: justify;
  color: rgba(0, 0, 0, 0.6);
}

.TikTokVideoDescription > h1 {
  margin-bottom: 16px;
}

.VideoCard {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.Video {
  box-shadow: rgba(0, 0, 0, 0.9) 0px 3px 12px;
  background-color: black;
  background-size: cover;
  border-radius: 20px;
  flex: 1.5;
  position: relative;
  left: -20px;
  top: -20px;
  min-width: 200px;
  min-height: 200px;
  overflow: hidden;
  display: flex;
  align-content: center;
  justify-content: center;
  max-height: 400px;
}

.TikTokVideo {
  box-shadow: rgba(0, 0, 0, 0.9) 0px 3px 12px;
  background-color: black;
  background-size: cover;
  border-radius: 20px;
  flex: 1.5;
  position: relative;
  right: -20px;
  top: -20px;
  min-width: fit-content;
  overflow: hidden;
  display: flex;
  align-content: center;
  justify-content: center;
  aspect-ratio: 9/16;
  max-width: 320px;
  max-height: 90vh;
}

.Video > iframe {
  border: none;
  width: 100%;
}

@media (max-width: 700px) {
  .VideoDescription {
    padding: 0px 20px 20px 10px;
    flex: 1.5;
    min-width: 200px;
  }

  .VideoDescription > div {
    padding-left: 16px;
    margin: 0px;
    text-align: justify;
    color: rgba(0, 0, 0, 0.6);
  }

  .VideoDescription > h2 {
    margin-bottom: 16px;
    margin: 0px;
    padding-left: 16px;
  }

  .TikTokVideoDescription {
    padding: 20px 20px 20px 10px;
    flex: 1.5;
    min-width: 200px;
  }

  .TikTokVideoDescription > div {
    padding-left: 16px;
    margin: 0px;
    text-align: justify;
    color: rgba(0, 0, 0, 0.6);
  }

  .TikTokVideoDescription > h2 {
    margin-bottom: 16px;
    margin: 0px;
    padding-left: 16px;
  }

  .TikTokVideoCardContainer {
    width: 80%;
  }
}

.SubHeaderSmall {
  color: rgba(0, 0, 0, 0.6);
  padding: 6px;
}

.LegendItem {
display: flex;
flex-direction: row;
align-items: center;
justify-content: flex-end;
}
.GrayIcon {
  height: 21px;
  width: 21px;
}

.GrayIcon > img {
  width: 100%;
}

.IconLabel{
  padding: 6px;
  font-size: 8px;
  color: gray;
}
