@import-normalize; /* bring in normalize.css styles */

@font-face {
  font-family: headerFont;
  src: url(assets/VarelaRound-Regular.ttf);
  font-display: swap;

}

h1 {
  font-family: headerFont, 'verdana';
}

h2, h3, h4 {
  font-family: headerFont, 'verdana';
}


p {
  line-height: 1.3;
}

body {
  margin: 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(251, 251, 251);
}

textarea, input {
  font-family: inherit;
}