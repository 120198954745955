.FormContainer  form {

    display: flex;
    flex-direction: column;
    justify-items: stretch;


}

.FormField {
    margin-bottom: 12px;
    display: flex;
    flex-direction: column;
}

.FormContainer {
    max-width: 1000px;
    width:80%;
    background: white;
    border-radius: 20px;
    padding: 6px 24px 10px 24px;
    margin: 24px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);
  
}

*:focus {
    outline: none;
  }

.FormContainer form{
    padding: 0 0 0 0;
  }
  .FormContainer form .form-row{
    display: flex;
    margin: 24px 0;
  }

form .form-row .input-data{
  width: 100%;
  height: 40px;
  margin: 0 20px;
  position: relative;
}
form .form-row .textarea{
  height: 60px;
  padding: 0;
}
.input-data input,
.textarea textarea{
  display: block;
  width: 100%;
  height: 100%;
  border: none;
  font-size: 17px;
  padding: 0;
  border-bottom: 2px solid rgba(0,0,0, 0.12);
}
.input-data input:focus ~ label, .textarea textarea:focus ~ label,
.input-data input:valid ~ label, .textarea textarea:valid ~ label{
  transform: translateY(-20px);
  font-size: 14px;
  font-weight: bold;
  color: #9311a7;
}
.textarea textarea{
  resize: none;
  padding: 0;
}
.input-data label{
  position: absolute;
  pointer-events: none;
  bottom: 10px;
  font-size: 16px;
  transition: all 0.3s ease;
}
.textarea label{
  width: 100%;
  bottom: 40px;
  background: #fff;
}
.input-data .underline{
  position: absolute;
  bottom: 0;
  height: 2px;
  width: 100%;
}
.input-data .underline:before{
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  background: #9311a7;
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.3s ease;
}
.input-data input:focus ~ .underline:before,
.input-data input:valid ~ .underline:before,
.textarea textarea:focus ~ .underline:before,
.textarea textarea:valid ~ .underline:before{
  transform: scale(1);
}
.submit-btn .input-data{
  overflow: hidden;
  height: 45px!important;
  width: 25%!important;
}
.submit-btn .input-data .inner{
  height: 100%;
  width: 300%;
  position: absolute;
  left: -100%;
  transition: all 0.4s;
}
.submit-btn .input-data:hover .inner{
  left: 0;
}
.submit-btn .input-data input{

  color: black;
  cursor: pointer;
  position: relative;
  z-index: 2;
}

@media (max-width: 700px) {
  .container .text{
    font-size: 30px;
  }
  .container form{
    padding: 10px 0 0 0;
  }
  .container form .form-row{
    display: block;
  }
  form .form-row .input-data{
    margin: 20px 0!important;
  }
  .submit-btn .input-data{
    width: 40%!important;
  }

  .FormContainer form .form-row {
    display: flex;
    margin: 12px 0;}
}


input {
    padding:0;
}
