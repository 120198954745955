.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.AboutPage {
  display: flex;
  justify-items: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}

.SubHeaderSmall {
  color: rgba(0, 0, 0, 0.6);
  padding: 6px;
}

.page {
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 200ms, transform 200ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 200ms, transform 200ms;
}

.showing {
  position: fixed; /* Sit on top of the page content */
  display: flex;
  align-items: center;
  justify-content: center; /* Hidden by default */
  width: 100vw; /* Full width (cover the whole page) */
  height: 100vh; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white; /* Black background with opacity */
  z-index: 50; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  transition-duration: (0, 1000ms);
  transition-property: (visibility, opacity);
  transition-delay:(0);
  opacity: 1;
}

.showing.hidden {
  visibility: hidden;
  opacity: 0;
}

@-webkit-keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

@keyframes fadeinout {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.logoLoader {
  -webkit-animation-name: fadeinout;
  animation: fadeinout 1s ease-in-out 0 alternate;
  -webkit-animation-timing-function: ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-duration: 1s;
  -webkit-animation-direction: alternate;
  opacity: 1;
}
