.FooterContainer {
    background-color: #6e6b7112;
    display: flex;
   justify-content: space-between;
    flex-wrap: wrap;
    color: rgba(0, 0, 0, 0.66);
}

.FooterSection {
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

.TextSmall {
    font-size: 10px;
}

.BottomAligned {
    justify-content: flex-end;
}

.AppLogo {
    max-width: 200px;
}