.HeaderContainer {
  position: sticky;
  top: 12px;
  display: flex;
  padding: 12px;
  align-items: center;
  margin-bottom: 12px;
  background-color: white;
  border-radius: 24px;
  width: fit-content;
  box-shadow: rgba(0, 0, 0, 0.09) 0px 3px 12px;  margin-top: 6px;
  margin-left: -16px;
  z-index:20;
}

.MenuLink {
    font-family: headerFont, 'verdana';
  margin-right: 8px;
  padding-bottom: 4px;
  --s: 2px; /* height of the line */
  --c: #9311a7; /* color*/

  text-decoration: none;
  display: inline-block;
  background: radial-gradient(farthest-side, var(--c) 98%, transparent 100%)
      bottom right/var(--s) var(--s),
    radial-gradient(farthest-side, var(--c) 98%, transparent 100%) bottom left /
      var(--s) var(--s),
    linear-gradient(var(--c), var(--c)) bottom/calc(100% - var(--s)) var(--s);
  background-repeat: no-repeat;
}

.MenuLink:hover {
  margin-right: 8px;
  padding-bottom: 4px;
  --s: 2px; /* height of the line */
  --c: black; /* color*/

  text-decoration: none;
  display: inline-block;
  background: radial-gradient(farthest-side, var(--c) 98%, transparent 100%)
      bottom right/var(--s) var(--s),
    radial-gradient(farthest-side, var(--c) 98%, transparent 100%) bottom left /
      var(--s) var(--s),
    linear-gradient(var(--c), var(--c)) bottom/calc(100% - var(--s)) var(--s);
  background-repeat: no-repeat;
  transition: background 1s ease-in;
}

.HeaderLogo {
  margin-right: 20px;
}

.Logo {
  height: 50px;
  background-image: url("./../../assets/piotrek_logo.png");
  background-repeat: no-repeat;
  width: 100px;
  background-position-y: center;
  background-size: contain;
  margin-right: 20px;
  margin-left: 20px;
}

a {
  color: inherit;
  text-decoration: none;
}
